import {Image, Navbar, NavbarBrand, NavbarContent, NavbarItem, Button} from "@nextui-org/react";
import logo from './images/boxing-gloves.png';
import Header from "./Header";
import TournamentsPreview from "./TournamentsPreview";
import CreateTournament from './CreateTournament';
import {BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';

const Home = () => {
    return (  
    <Router>
    <Navbar>
            <NavbarBrand>
                <Link to="/">
                <Image
                    width={50}
                    alt="Logo"
                    src={logo} />
                </Link>
            </NavbarBrand>
            <NavbarContent className="sm:flex gap-4 hidden" justify="center">
                <NavbarItem>
                    <Link to="/" >
                        Tournaments
                    </Link>
                </NavbarItem>
                <NavbarItem>
                    <Link to="/create" className="text-sky-600">
                        Create
                    </Link>
                </NavbarItem>
            </NavbarContent>
            <NavbarContent justify="end">
                <NavbarItem className="lg:flex">
                    <Link href="#">Login</Link>
                </NavbarItem>
                <NavbarItem>
                    <Button as={Link} color="primary" href="#" variant="flat">
                        Sign Up
                    </Button>
                </NavbarItem>
            </NavbarContent>
        </Navbar>
        <div id="content">
            <Routes>
                <Route 
                exact path="/" 
                element={<><Header /><TournamentsPreview /><div class="ml-embedded p-20" data-form="nKbBFF"></div></>}
                />
                <Route 
                path="/create"
                element={                    <div className="create-tournament">
                <CreateTournament />

            </div>}
                />

            </Routes>
        </div>
    </Router>
    );
}
 
export default Home;