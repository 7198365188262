import logo from './logo.svg';
import './App.css';
import {NextUIProvider} from "@nextui-org/react";
import Home from './Home';
import {Helmet} from 'react-helmet';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    function loadMailerLiteScript() {
      (function (w, d, e, u, f, l, n) {
        w[f] = w[f] || function () {
          (w[f].q = w[f].q || []).push(arguments);
        };
        l = d.createElement(e);
        l.async = 1;
        l.src = u;
        n = d.getElementsByTagName(e)[0];
        n.parentNode.insertBefore(l, n);
      })(window, document, 'script', 'https://assets.mailerlite.com/js/universal.js', 'ml');

      // Set up your MailerLite account here once the script has loaded
      window.ml('account', '645884');
    }

    // Check if the MailerLite script has already been loaded
    if (window.ml) {
      // If it's already available, set up your MailerLite account
      window.ml('account', '645884');
    } else {
      // If not, load the script and set up your account once it's loaded
      loadMailerLiteScript();
    }

  }, []); 

  return (
    <NextUIProvider>
      <Home />
      
    </NextUIProvider>
  );
}

export default App;
