import {Button, Image} from "@nextui-org/react";
import headerImage from './images/Achievement-pana.png';
import { Link } from 'react-router-dom';

const Header = () => {
    return ( 
        <header className="App-header">
            <div className="grid grid-rows-1 lg:grid-cols-2">
                <div className="md:mx-20 lg:p-20">
                    <h1 className="text-lg lg:text-7xl">Organize and compete in tournaments</h1>
                    <div class="button-wrapper">
                        <Button className="bigger-button" radius="full" color="primary" variant="solid">
                            Play
                        </Button>
                    </div>
                    <div class="button-wrapper">
                        <Button className="bigger-button" radius="full" color="warning" variant="shadow">
                            <Link to="/create">Create</Link>
                        </Button>
                    </div>
                </div>
                <Image 
                className=""
                    width={700}
                    alt="Achievement"
                    src={headerImage}/>
            </div>
        </header>
     );
}
 
export default Header;