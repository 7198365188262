import { Button, Input, Radio, RadioGroup, Textarea, Image } from "@nextui-org/react";
import { useState } from "react";
import image_input from './images/image.png';
import { FaEye, FaEyeSlash } from "react-icons/fa6";


const Password = ({isPrivate}) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => setIsVisible(!isVisible);
    if (isPrivate) {
        return (
            <Input
            className="mt-5 max-w-sm"
                label="Password"
                variant="bordered"
                endContent={
                    <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                      {isVisible ? (
                        <FaEyeSlash className="text-2xl text-default-400 pointer-events-none" />
                      ) : (
                        <FaEye className="text-2xl text-default-400 pointer-events-none" />
                      )}
                    </button>
                  }
                  type={isVisible ? "text" : "password"}
            />
        );
    }
    return (
        <div className="hidden">

        </div>
    );
}

const CreateTournament = () => {
    const [isPrivate, setIsPrivate] = useState(false);
    const [numberOfParticipants, setNumberOfParticipants] = useState(0);
    const maxParticipants = 10;

    const participantsChange = (value) => {
        setNumberOfParticipants(value);
        console.log(value);
    };

    return (  
        <div className="p-10">
            <h1 className="text-lg lg:mx-10">Create a tournament</h1>
            <div className="grid grid-rows-4-dense md:grid-flow-col gap-4">
                    <Input 
                        className="max-w-xl lg:mx-10" type="text" label="Title" variant="bordered"
                    />
                    <Input 
                        className="max-w-xl lg:mx-10" type="text" label="Game" variant="bordered"
                    />
                    <Input 
                        className="max-w-xl lg:mx-10" type="number" label="Number of participants" variant="bordered" onValueChange={participantsChange} isInvalid={numberOfParticipants > maxParticipants}
                    />
                    <Button variant="flat" color="primary" className="row-span-3 h-100 max-w-sm mx-auto aspect-square" ><Image width={100} src={image_input}/></Button>
            </div>
            <div className="grid grid-flow-row auto-rows-max mt-10">
                <Textarea 
                className="max-w-xl lg:mx-10"
                    label="Description"
                    variant="bordered"
                    labelPlacement="outside"
                    placeholder="Enter the description of your tournament"
                />
                <div className="lg:mx-10">
                    <RadioGroup label="Is private?" orientation="horizontal" value={isPrivate} onValueChange={setIsPrivate}>
                        <Radio value={false}>No</Radio>
                        <Radio value={true}>Yes</Radio>
                    </RadioGroup>
                    <Password isPrivate={isPrivate} />
                </div>
                <Button className="mx-auto mt-5" size="lg" color="warning">
                    Create
                </Button>
            </div>
        </div>
    );
}
 
export default CreateTournament;