import { Button, Card, CardBody, CardFooter, CardHeader, Image, } from "@nextui-org/react";
import { FaArrowRight, FaUserGroup } from "react-icons/fa6";
import templateImage from './images/rocketleague.webp';

const TournamentsPreview = () => {
    const tournaments = [
        { title: "Tournament 1", img: "./images/boxing-gloves.png", date:"2023-01-01"},
        { title: "Tournament 2", img: "./images/boxing-gloves.png", date:"2023-01-01"},
        { title: "Tournament 3", img: "./images/boxing-gloves.png", date:"2023-01-01"},
        { title: "Tournament 4", img: "./images/boxing-gloves.png", date:"2023-01-01"},
    ];

    return (  
        <div className="p-5">
            <h2>Explore upcoming tournaments</h2>
            <div className="w-full gap-2 grid grid-rows-1 md:grid-cols-5">
            {tournaments.map((tournament, index) => (
                <Card shadow="sm" key={index} isPressable onPress={() => console.log("item pressed")}>
                    <CardHeader className="flex text-left gap-3">
                        <div className="flex flex-col ">
                        <h3>{tournament.title}</h3>
                        <h5>{tournament.date}</h5>
                        </div>
                    </CardHeader>
                    <CardBody className="overflow-visible p-0">
                        <Image 
                            shadow="sm"
                            radius="lg"
                            width="100%"
                            alt={tournament.title}
                            className="w-full object-cover h-[280px]"
                            src={templateImage}
                            /**src={tournament.img}*/
                        />
                    </CardBody>
                    <CardFooter>
                    <div className="flex flex-none mr-2">
                                5/6
                        </div>
                        <div className="flex flex-auto">
                                <FaUserGroup />

                        </div>
                        <div className="flex flex-auto justify-end">
                            <FaArrowRight />
                        </div>
                    </CardFooter>
                </Card>
            ))}
            <Button className="mt-auto mb-auto h-full" variant="light">  
                <FaArrowRight className="scale-150"/>
            </Button>
        </div>
        </div>
    );
}
 
export default TournamentsPreview;